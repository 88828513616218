import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { useState } from 'react'

interface IProps {
  questions: {
    contents: string
    title: string
  }[]
  activeQuestion?: number
  accordionSingleClasses?: string
  contentClasses?: string
}

const FaqSection = ({ questions, activeQuestion, accordionSingleClasses, contentClasses }: IProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(activeQuestion ?? -1)

  const handleClick = (index: number) => {
    setActiveIndex(index === activeIndex ? -1 : index)
  }

  return (
    <div className="m-0 mt-8 list-none p-0">
      {questions.map((question, index) => (
        <div
          onClick={() => handleClick(index)}
          className={classNames('mb-1 cursor-pointer border border-gray-200 drop-shadow-2xl transition-all hover:shadow-lg', accordionSingleClasses)}
          key={index}>
          <button className="flex w-full items-center p-4">
            <h3 className="flex-grow text-left font-bold text-brand-secondary">{question.title}</h3>
            <span className="flex justify-self-end text-brand-secondary">
              {index === activeIndex ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
            </span>
          </button>
          <p
            className={classNames(contentClasses ?? 'mb-4 -mt-3 max-w-3xl px-6 pb-3 text-left leading-loose')}
            dangerouslySetInnerHTML={{ __html: question.contents }}
            style={{ display: index === activeIndex ? 'block' : 'none' }}
          />
        </div>
      ))}
    </div>
  )
}

export default FaqSection
